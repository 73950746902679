import { useMemo, useState } from 'react';

import Buttons from 'components/Buttons';
import HollowButton from 'components/HollowButton';
import SaveButton from 'components/SaveButton';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PanelTitle';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';

import styles from './styles.module.scss';
import useTranslation from './hooks/useTranslation';
import Table from 'components/Table';
import TableHeader from 'components/TableHeader';
import TableRow from 'components/TableRow';
import formatDate from 'utilities/formatDate';
import { computeRecurrenceFutureDueDate } from 'utilities/recurrence-dates';

const UpdateRecurrenceEndDateModal = ({
    daysBeforeDueDate,
    dueDate,
    onClose,
    onSave,
    recurrenceType,
    recurrences
}) => {
    const { UPDATE_FUTURE_ACTIVITIES } = useTranslation();
    const [_updatedActivities, updateActivities] = useData();

    const recurrenceWithFutureDates = useMemo(
        () =>
            computeRecurrenceFutureDueDate({
                daysBeforeDueDate,
                dueDate,
                recurrenceType,
                recurrences
            }),
        [daysBeforeDueDate, dueDate, recurrenceType, recurrences]
    );

    const [sortBy, setSortBy] = useState(null);

    const headerItems = [
        {
            isSortable: false,
            key: 'updated_date',
            text: 'Due Date'
        }
    ];

    const handleClose = () => {
        if (isFunction(onClose)) {
            onClose();
        }
    };

    const handleSaveFuture = () => {
        updateActivities({
            onSuccess: onSave,
            recurrences: recurrenceWithFutureDates
        });
        handleClose();
    };

    const visibleRecurrences = (recurrenceWithFutureDates ?? []).filter(
        recurrence => !isNaN(new Date(recurrence.futureDueDate))
    );

    return (
        <Modal isOpen={true} onClose={handleClose} overlayClose={false}>
            <PanelBody>
                <PanelTitle
                    className={styles.panelTitle}
                    text={UPDATE_FUTURE_ACTIVITIES}
                />

                <Table className={styles.table}>
                    <TableHeader
                        className={styles.updateDatesHeader}
                        items={headerItems}
                        onSort={setSortBy}
                        sortBy={sortBy}
                    />

                    {visibleRecurrences.map((recurrence, index) => {
                        return (
                            <TableRow
                                className={styles.updateDatesRow}
                                key={`row_${index}`}
                            >
                                {formatDate(recurrence.futureDueDate)}
                            </TableRow>
                        );
                    })}
                </Table>

                <Buttons>
                    <SaveButton
                        onClick={handleSaveFuture}
                        saveText={'Update Future'}
                    />

                    <HollowButton
                        onClick={handleClose}
                        text={'Do Not Update'}
                    />
                </Buttons>
            </PanelBody>
        </Modal>
    );
};

export default UpdateRecurrenceEndDateModal;
