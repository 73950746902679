import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CancelSaveButtons from 'components/CancelSaveButtons';
import TextBox from 'components/TextBox';
import TextInput from 'components/TextInput';
import DatePicker from 'components/DatePicker';
import Dropdown from 'components/Dropdown';
import DropdownWithTags from 'components/DropdownWithTags';
import PanelTitle from 'components/PanelTitle';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import RiskLevelRadioButtons from 'components/RiskLevelRadioButtons';
import SplitContainer from 'components/SplitContainer';
import StandoutText from 'components/StandoutText';
import WithLabel from 'components/WithLabel';

import useConfig from 'hooks/useConfig';
import useForm from 'hooks/useForm';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import isFunction from 'utilities/isFunction';

import useActivityId from './hooks/useActivityId';
import useCaseId from './hooks/useCaseId';
import useData from './hooks/useData';
import useDataQuery from './hooks/useDataQuery';
import useTranslation from './hooks/useTranslation';
import useUsers from './hooks/useUsers';
import useTypes from './hooks/useTypes';

import styles from './styles.module.scss';

import normalizeDefaultCaseTypeData from './dataDefaults';
import normalizeTypeData from './dataTypes';
// import normalizeUserData from './dataUsers';
import titleCase from 'utilities/titleCase';

const CaseCreationReasonModal = ({ 
    activityRecord,
    caseRecord,
    onClose,
    toClose = true,
    onSave,
    linkSchema
    // riskLabel 
}) => {
    const activityRiskLabel = activityRecord?.activityRiskLabel ? activityRecord?.activityRiskLabel : "";
    const name = activityRecord?.name ? activityRecord?.name : caseRecord?.caseName ? caseRecord?.caseName : "";
    const type = activityRecord?.type ? activityRecord?.type : caseRecord?.type?.name ? caseRecord?.type?.name : "";

    const { CASE_ROUTE, INTERNAL_SERVER_ERROR } = useConfig();

    const {
        ACTIVITY_NAME,
        ACTIVITY_TYPE,
        ASSIGNED_TO,
        CASE_CREATION_REASON,
        CASE_NAME,
        CASE_TYPE,
        CLOSE_AND_CREATE_A_CASE,
        CREATE_A_CASE,
        DUE_DATE,
        ENTER_CASE_NAME,
        LINKED_CASE_NAME,
        LINKED_CASE_TYPE,
        REASON,
        RISK_LABEL_CASE
    } = useTranslation();

    const navigate = useNavigate();

    const { useConsultancyUsers, useSchema, useTenants, useUserId, useUserSetSchema } = useGlobalStateHooks();

    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userId] = useUserId();
    const [consultancyUsers] = useConsultancyUsers();
    const [userSetSchema, setUserSetSchema] = useUserSetSchema();
    const [activityLink, setActivityLinkId] = useActivityId();
    const [caseLink, setCaseLinkId] = useCaseId();

    const providedSchema = linkSchema ? linkSchema : activityRecord?.schema ? activityRecord?.schema : caseRecord?.schema ? caseRecord?.schema : userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;

    const [savedCaseCreationReason, saveCaseCreationReason, reset] = useData(providedSchema);
    const [users, setUsers] = useUsers();

    const [_errorMessage, setErrorMessage] = useState();
    const [defaultCaseTypeData, setDefaultCaseTypeData] = useState({});
    const [selectedRiskLevel, setSelectedRiskLevel] = useState(activityRiskLabel);
    const [types, setTypes] = useTypes();

    const form = useForm({
        caseName: '',
        caseType: '',
        creationReason: '',
        dueDate: '',
        riskLabel: '',
        usersAssignedTo: '',
    });

    const { handleError, handleForm, handleSubmit, handleValid } = form;

    const handleClose = useCallback(
        event => {
            if (isFunction(onClose)) {
                setActivityLinkId('');
                setCaseLinkId('');
                return onClose(event);
            }
        },
        [onClose, setActivityLinkId, setCaseLinkId]
    );

    const { _loading, error, data } = useDataQuery(providedSchema);

    const handleSetRiskLevel = (clickedRiskLevel) => {
        if (selectedRiskLevel === clickedRiskLevel) {
            setSelectedRiskLevel('');
        } else {
            setSelectedRiskLevel(clickedRiskLevel);
        }
    };

    const handleSave = useCallback(() => {
        const output = handleForm();
        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }

        const formObject = Array.from(output.entries()).reduce(
            (accumulator, [key, value]) => {
                accumulator[key] = value;

                return accumulator;
            },
            {
                activityId: activityRecord?.id,
                createdBy: parseInt(userId),
                linkActivity: activityLink?.activityId || null,
                linkCase: caseLink?.caseId || null,
                originated: 'from case',
                policies: activityRecord?.policyReference.map(({ id }) => id),
                toClose
            }
        );

        if (!formObject.caseType) {
            formObject.caseType = defaultCaseTypeData.id;
        } else {
            formObject.caseType = parseInt(formObject.caseType)
        }

        const date = new Date();
        formObject.dueDate = (formObject?.dueDate === '' || !formObject?.dueDate) ? 
            new Date(date.setDate(date.getDate() + 180)) : 
            new Date(formObject.dueDate);

        formObject.riskLabel = selectedRiskLevel;

        formObject.formId = formObject?.caseType !== '' ? types.filter((t) => t.value === Number(formObject?.caseType))[0].formId : null;

        saveCaseCreationReason(formObject);
    }, [
        activityLink,
        activityRecord,
        caseLink,
        defaultCaseTypeData.id,
        handleForm,
        saveCaseCreationReason,
        selectedRiskLevel,
        toClose,
        userId
    ]);

    const setTenant = selectedTenant => {
        setUserSetSchema(selectedTenant);
    }

    useEffect(() => {
        if (data) {
            const normalizedDefaultCaseTypeData = normalizeDefaultCaseTypeData({ data, schema:providedSchema, tenants })[0];
            console.log(data)
            const normalizedTypeData = normalizeTypeData({ data, schema:providedSchema, tenants });
            // const normalizedUserData = normalizeUserData({ data, schema:tenantSchema, tenants });

            if (normalizedDefaultCaseTypeData?.id) {
                setDefaultCaseTypeData(normalizedDefaultCaseTypeData);
            }

            const filteredTypes = userSetSchema ? normalizedTypeData.filter(function(type){ return type.schema === providedSchema;}) : normalizedTypeData;

            setTypes(filteredTypes);
            setUsers(consultancyUsers.filter(item => item.tenants.includes(providedSchema)));
        }
    }, [data, schema, setTypes, setUsers, tenants, userSetSchema]);

    useEffect(() => {
        if (!savedCaseCreationReason) {
            return;
        }

        if (isFunction(onClose)) {
            setActivityLinkId('');
            setCaseLinkId('');
            onClose(savedCaseCreationReason);

            if (isFunction(onSave)) {
                onSave();
            }else{
                navigate(CASE_ROUTE.replace(':caseId', savedCaseCreationReason).replace(':caseSchema', providedSchema));
            }
        
            reset();
            window.location.reload()
        }
    }, [CASE_ROUTE, navigate, savedCaseCreationReason, onClose, onSave, reset, providedSchema, setActivityLinkId, setCaseLinkId]);
    

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);
        return;
    }

    return (
        <Modal isOpen={true} onClose={handleClose}>
            <PanelBody>
                <PanelTitle
                    className={styles.largeTitle}
                    text={CASE_CREATION_REASON}
                />

                { (activityRecord?.name || caseRecord?.caseName) &&
                    <SplitContainer>
                        <WithLabel
                            className={styles.withLabel}
                            text={`${activityRecord?.name ? ACTIVITY_NAME : LINKED_CASE_NAME}:`}
                        >
                            <StandoutText text={name} />
                        </WithLabel>

                        <WithLabel
                            className={styles.withLabel}
                            text={`${activityRecord?.type ? ACTIVITY_TYPE : LINKED_CASE_TYPE}:`}
                        >
                            <StandoutText text={type} />
                        </WithLabel>
                    </SplitContainer>
                }

                <Dropdown
                    className={styles.dropdown}
                    items={types ? types : []}
                    label={CASE_TYPE}
                    onError={handleError('caseType')}
                    onSubmit={handleSubmit('caseType')}
                    onValid={handleValid('caseType')}
                    placeholder={defaultCaseTypeData.id ? '' : 'Select a Case Type'}
                    selected={defaultCaseTypeData.id ? types.findIndex(type => type.value === defaultCaseTypeData.id) : ''}
                />

                {!providedSchema && tenants.length > 1 &&
                    <Dropdown
                        className={styles.dropdownWide}
                        items={tenants.map(tenant => ({label: titleCase(tenant), value: tenant}))}
                        label="Tenant"
                        onChange={setTenant}
                        onError={handleError('tenant')}
                        onSubmit={handleSubmit('tenant')}
                        onValid={handleValid('tenant')}
                    />
                }

                <TextInput
                    label={CASE_NAME}
                    onChange={handleValid('caseName')}
                    onError={handleError('caseName')}
                    onSubmit={handleSubmit('caseName')}
                    placeholder={ENTER_CASE_NAME}
                />

                <TextBox
                    label={REASON}
                    onChange={handleValid('creationReason')}
                    onSubmit={handleSubmit('creationReason')}
                />

                <RiskLevelRadioButtons
                    className={styles.riskLevelRadioButtons}
                    label={RISK_LABEL_CASE}
                    onChange={handleSetRiskLevel}
                    riskLabel={selectedRiskLevel}
                />

                <DropdownWithTags
                    items={users}
                    label={ASSIGNED_TO}
                    onChange={handleValid('usersAssignedTo')}
                    onSubmit={handleSubmit('usersAssignedTo')}
                />

                <DatePicker
                    date={'2022-09-22T16:38:07.436Z'}
                    label={DUE_DATE}
                    onChange={handleValid('dueDate')}
                />

                <CancelSaveButtons
                    className={styles.cancelSaveButtons}
                    onCancel={handleClose}
                    onSave={handleSave}
                    saveText={toClose ? CLOSE_AND_CREATE_A_CASE : CREATE_A_CASE}
                />
            </PanelBody>
        </Modal>
    );
};

export default CaseCreationReasonModal;
