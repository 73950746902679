import isFunction from 'utilities/isFunction';

import CheckboxWithLabelDynamicReturn from 'components/CheckboxWithLabelDynamicReturn';
import Dropdown from 'components/Dropdown';
import DynamicRadioButtons from 'components/DynamicRadioButtons';
import Label from 'components/Label';
import TextInput from 'components/TextInput';
import TextareaInput from 'components/TextareaInput';

import styles from './styles.module.scss';

const ActivityFormComponent = props => {
    const {
        answer,
        choices,
        handleError,
        handleForm,
        handleSubmit,
        handleValid,
        id,
        inactive,
        name,
        questionId,
        questions,
        setQuestions,
        setUpdatedQuestions,
        type,
        updatedQuestions
    } = props;

    const handleChange = (answer, _options) => {
        const newQuestions = [];
        const newUpdatedQuestions = updatedQuestions && Array.isArray(updatedQuestions) ? [...updatedQuestions] : [];
        for(const ques of questions){
            if(ques?.id !== id){
                newQuestions.push({...ques});
            }else{
                newQuestions.push({...ques, answer})
            }
        }
        if(isFunction(setQuestions)){
            setQuestions(newQuestions);
        }
        if(updatedQuestions && Array.isArray(updatedQuestions) && !updatedQuestions.includes(id)){
            newUpdatedQuestions.push(id);
        }
        if(isFunction(setUpdatedQuestions)){
            setUpdatedQuestions(newUpdatedQuestions);
        }
        if (isFunction(handleValid)) {
            handleValid(`${id}`);
        }
    };

    const handleCheckboxChange = (label, value) => {
        const clickedItem = choices.findIndex(x => x?.label === label);
        const currentAnswer = questions.filter(x => x?.id === id)[0]?.answer;
        const currentAnswerArray = currentAnswer && Array.isArray(currentAnswer.split(",")) && currentAnswer.split(",").map(x => parseInt(x)) ? currentAnswer.split(",").map(x => parseInt(x)) : [];
        const newAnswerArray = [...currentAnswerArray];
        if(value){
            newAnswerArray.push(clickedItem);
        }else{
            const index = newAnswerArray.indexOf(clickedItem);
            if (index !== -1) {
                newAnswerArray.splice(index, 1);
            }
        }
        const newAnswer = newAnswerArray.join(',');
        handleChange(newAnswer, choices);
    };

    if(type === 'title'){
        return(<h2 className={styles.formField} >{name}</h2>);
    }else if(type === 'text'){
        return(<p className={styles.formField} >{name}</p>);
    }else if(type === 'textInput'){
        return(
            <TextInput
                className={styles.formField}
                isDisabled={inactive}
                label={name}
                onChange={handleChange}
                onError={handleError(`${id}`)}
                onSubmit={handleSubmit(`${id}`)}
                value={answer}
            />
        );
    }else if(type === 'textAreaInput'){
        return(
            <TextareaInput
                className={styles.formField}
                isDisabled={inactive}
                label={name}
                onChange={handleChange}
                onError={handleError(`${id}`)}
                onSubmit={handleSubmit(`${id}`)}
                text={answer}
            />
        );
    }else if(type === 'radioInput'){
        const choicesWithAnswer = choices.reduce((accumulator, choice) => {
                const { label, name, value } = choice;
                const checked = value === answer ? true : false;
                const updatedChoice = {
                    isChecked: checked,
                    label,
                    name,
                    value
                }
                accumulator.push(updatedChoice);
                return accumulator
            }, []);
        return(
            <>
            <Label className={styles.formField} text={name} />

            <DynamicRadioButtons
                className={`${styles.radioButtons} ${styles.formField}`}
                globalIsDisabled={inactive}
                items={choicesWithAnswer}
                onChange={handleChange}
                onError={handleError(`${id}`)}
                onSubmit={handleSubmit(`${id}`)}
                selected={answer}
            />
            </>
        )
    }else if(type === 'dropDownInput'){
        const selectedAnswer = choices ? choices.filter(function (choice) {return choice.value === answer })[0] : [];
        const selectedAnswerIndex = choices?.indexOf(selectedAnswer);
        return (
            <Dropdown
                className={styles.formField}
                isDisabled={inactive}
                items={choices}
                label={name}
                onChange={handleChange}
                onError={handleError(`${id}`)}
                onSubmit={handleSubmit(`${id}`)}
                selected={selectedAnswerIndex}
            />
        );
    }else if(type === 'checkBoxInput'){
        return (
            <div className={styles?.checkbox}>
                <Label text={name} />

                {choices.map((option, index) => {
                    return(
                        <div>
                            <CheckboxWithLabelDynamicReturn
                                isChecked={answer && Array.isArray(answer.split(",")) && answer.split(",").map(x => parseInt(x)).includes(index)}
                                isDisabled={inactive}
                                onChange={handleCheckboxChange}
                                onError={handleError(`${id}_${index}`)}
                                onSubmit={handleSubmit(`${id}_${index}`)}
                                text={option.label}
                            />
                        </div>
                    );
                })}
            </div>
        );
    }else if(type === 'conditional'){
        // eslint-disable-next-line
        const subQuestions = questions.map((subQuestion) => {
            const formComponent = subQuestion?.formComponent ? subQuestion?.formComponent.split("_") : [];
            if(formComponent[1]===questionId){
                const dependantQuestions = questions.filter(e => e?.name === name && e?.type !== 'conditional');
                const dependantQuestion = dependantQuestions[0];
                const selected = Array.isArray(dependantQuestion?.answer) ? dependantQuestion?.answer : [dependantQuestion?.answer];
                const dynamics = [];
                for(const selectedAnswer of selected){
                    var statementOfTruth;
                    if(dependantQuestion?.type !== 'checkBoxInput'){
                        statementOfTruth = formComponent[2].toLowerCase() === selectedAnswer;
                    }else{
                        const answerArray = [];
                        const indexArray = selectedAnswer ? selectedAnswer.split(',') : [];
                        for(const i of indexArray){
                            answerArray.push(dependantQuestion?.choices[i]?.value);
                        }
                        statementOfTruth = answerArray.includes(formComponent[2].toLowerCase());
                    }

                    if(statementOfTruth){
                        dynamics.push(<ActivityFormComponent
                                answer={subQuestion?.answer}
                                choices={subQuestion?.choices}
                                formComponent={subQuestion?.formComponent}
                                handleError={handleError}
                                handleForm={handleForm}
                                handleSubmit={handleSubmit}
                                handleValid={handleValid}
                                id={subQuestion?.id}
                                inactive={inactive}
                                name={subQuestion?.name}
                                questionId={subQuestion?.questionId}
                                questions={questions}
                                setQuestions={setQuestions}
                                setUpdatedQuestions={setUpdatedQuestions}
                                type={subQuestion?.type}
                                updatedQuestions={updatedQuestions}
                            />
                        );
                    }
                }
                if(dynamics.length > 0){
                    return dynamics;
                }else{
                    return <></>;
                }
            }
        });
        return subQuestions;
    }else{
        return <></>
    }
};

export default ActivityFormComponent;
