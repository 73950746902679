import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import CancelSaveButtons from 'components/CancelSaveButtons';
import PageTitle from 'components/PageTitle';
import PersonalInformationPanel from 'components/PersonalInformationPanel';
import UploadPhotoPanel from 'components/UploadPhotoPanel';

import useForm from 'hooks/useForm';

import useData from './hooks/useData';
import useGroupsAndRoles from './hooks/useGroupsAndRoles';
import translations from './constants';

import normalizeData from './data';
import styles from './styles.module.scss';

const AddPerson = () => {
    const { ADD_NEW_PERSON } = translations();
    const { useSchema } = useGlobalStateHooks();

    const [schema] = useSchema();

    const { HUMAN_RESOURCES, INTERNAL_SERVER_ERROR } = useConfig();

    const [_errorMessage, setErrorMessage] = useState();

    const [addedPerson, addPerson] = useData();

    const [groups, setGroups] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [positions, setPositions] = useState([]);
    const [_userGroups, setUserGroups] = useState([]);
    const [_userDepartments, setUserDepartments] = useState([]);
    const [_userPositions, setUserPositions] = useState([]);

    const navigate = useNavigate();

    const form = useForm({
        department: '',
        emailAddress: '',
        firstName: '',
        lastName: '',
        position: ''
    });

    const { handleForm } = form;

    const handleCancel = useCallback(() => {
        navigate(HUMAN_RESOURCES);
    }, [HUMAN_RESOURCES, navigate]);

    const handleSave = useCallback(() => {
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }
        const formObject = {};

        output.forEach((value, key) => (formObject[key] = value));
        // addPerson(formObject);
        //eslint-disable-next-line
    }, [handleForm, setErrorMessage, addPerson]);

    useEffect(() => {
        
    }, [addedPerson]);

    const { _loading, error, data } = useGroupsAndRoles();

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                schema
            });

            setPositions(normalizedData?.positions);
            setGroups(normalizedData?.groups);
            setDepartments(normalizedData?.departments);
        }
    }, [data, schema, setDepartments, setPositions, setGroups]);

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);
        return;
    }

    return (
        <div className={styles.addPerson}>
            <PageTitle className={styles.pageTitle} text={ADD_NEW_PERSON} />

            <CancelSaveButtons
                className={styles.cancelSaveButtons}
                onCancel={handleCancel}
                onSave={handleSave}
            />

            <UploadPhotoPanel className={styles.uploadPhotoPanel} />

            <PersonalInformationPanel
                className={styles.personalInformationPanel}
                departments={departments}
                form={form}
                groups={groups}
                positions={positions}
                setDepartments={setUserDepartments}
                setGroups={setUserGroups}
                setPositions={setUserPositions}
            />
        </div>
    );
};

export default AddPerson;
